<template>
  <div>
    <b-modal
      id="edit-marque-modal"
      ref="edit-marque-modal"
      size="md"
      @hidden="cancel"
      :title="$t('modals.updateMarque.text')"
    >
      <!-- Name -->
      <div class="row">
        <div class="col-12 col-lg-12">
          <label for="name">{{$t("modals.name.text")}}</label>
          <b-form-input
            id="name"
            v-model="marqueEdit.name"
            class="mb-1"
            :placeholder="$t('modals.name.text')"
            :state="err.name ? false : null"
          ></b-form-input>
          <b-form-invalid-feedback>
            {{ err.name }}
          </b-form-invalid-feedback>
        </div>
      </div>

      <template #modal-footer="{ ok, cancel }">
        <div class="row p-2">
          <div class="col-10 text-right p-1">
            <b-button variant="success" class="text-white" @click="edit(ok)">
              {{$t("buttons.update.text")}}
            </b-button>
          </div>
          <div class="col-2 text-center p-1">
            <b-button @click="cancel()"> {{$t("buttons.cancel.text")}} </b-button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: ["marque"],
  created() {},
  data() {
    return {
      marqueEdit: {
        marqueId: null,
        name: null,
      },
      err: {
        name: null,
      },
    };
  },
  watch: {
    marque(newVal) {
      this.marqueEdit = {
        marqueId: newVal.id,
        name: newVal.name,
      };
    },
  },
  methods: {
    cancel() {
      this.clear();
    },
    clear() {
      /* this.marqueEdit = {
        marqueId: null,
        name: null,
      }; */
      this.err = {
        name: null,
      };
    },
    edit(ok) {
      if (!this.marqueEdit.name) this.err.name = this.$t("msgError.obligField.text");
      else this.err.name = "";

      if (!this.err.name) {
        if (this.marqueEdit.name != this.marque.name) {
          ok();
          this.$emit("editMarque", this.marqueEdit);
        } else {
          ok();
        }
      }
    },
  },
};
</script>