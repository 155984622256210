<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";

import MarquesData from "./marques-data";
import AddMarque from "./add-marque";

import {
  layoutComputed,
  authComputed,
  marquesMethods,
  marquesComputed,
} from "@/state/helpers";
/**
 * Marques component
 */
export default {
  page: {
    title: "Marques",
    meta: [{ name: "Marques" }],
  },
  components: {
    Layout,
    PageHeader,
    MarquesData,
    AddMarque,
  },
  mounted() {
    this.retrieveMarques();
  },
  data() {
    return {
      title: "titles.marques.text",
      busy: false,
      loaderDashboard: false,

      marquesData: [],
    };
  },
  computed: {
    ...layoutComputed,
    ...authComputed,
    ...marquesComputed,

    items(){
      return [
        {
          text: "Nextrack",
        },
        {
          text: this.$t("titles.marques.text"),
          active: true,
        },
      ]
    },

    userRole(){
      return this.loggedIn.user.role
    }
  },
  methods: {
    ...marquesMethods,

    retrieveMarques() {
      if (this.getMarques.results) {
        this.marquesData = this.getMarques.results;
      } else {
        this.busy = true;
        this.getMarquesBy({ limit: 100 }).then(() => {
          this.busy = false;
        });
      }
    },
    openAdd() {
      this.showModalAdd();
    },
    makeToast(title, msg, variant) {
      this.$bvToast.toast(msg, {
        title: title,
        variant: variant,
        solid: true,
      });
    },
    showModalAdd() {
      this.$bvModal.show("add-marque-modal");
    },
    hideModalAdd() {
      this.$bvModal.hide("add-marque-modal");
    },
    add(marque) {
      this.addMarque(marque)
        .then((marque) => {
          this.makeToast(
            "Add marque",
            marque.name + " has been added",
            "success"
          );
        })
        .catch((error) => {
          this.makeToast("Add marque", error, "danger");
        });
    },
  },
  watch: {
    getMarques(newVal) {
      this.marquesData = newVal.results;
    },
  },
  beforeDestroy() {},
};
</script>

<template>
  <Layout>
    <PageHeader :title="$t(title)" :items="items" />
    <div v-if="['SYSADMIN', 'ADMIN', 'MANAGER'].includes(userRole)" class="row">
      <div class="col-12 text-right">
        <b-button type="button" class="mr-2" variant="info" @click="openAdd"
          >{{$t("buttons.addMarque.text")}}
        </b-button>
        <AddMarque @addMarque="add" />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <MarquesData v-bind:marques="marquesData" :busy="busy"/>
      </div>
    </div>
  </Layout>
</template>